import 'admin-lte/plugins/jquery/jquery.min.js';
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js';
import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js';
// import 'admin-lte/plugins/fastclick/fastclick';
import 'admin-lte/dist/js/adminlte.min.js?v=3.2.0';
import 'admin-lte/plugins/inputmask/jquery.inputmask.min';
import 'admin-lte/plugins/jquery-validation/jquery.validate.min';
import 'admin-lte/plugins/select2/js/select2.full.min';
import 'admin-lte/plugins/bootstrap4-duallistbox/jquery.bootstrap-duallistbox.min';
// import 'admin-lte/plugins/moment/moment.min';

import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
// import 'admin-lte/plugins/flag-icon-css/css/flag-icon.min.css';
import 'admin-lte/plugins/flag-icon-css/css/flag-icons.min.css';
import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import 'admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css';
import 'admin-lte/plugins/bs-stepper/css/bs-stepper.min.css';
import 'admin-lte/dist/css/adminlte.min.css';
