import P from 'prop-types'
import React from 'react'
import { TemplateCtx } from '../contexts/TemplateContext'


const TemplateProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = React.useState('');
  const [breadcrumbs, setBreadcrumbs] = React.useState([]);
  const [filters, setFilters] = React.useState([]);

  return (
    <TemplateCtx.Provider value={{ pageTitle, setPageTitle, breadcrumbs, setBreadcrumbs, filters, setFilters }}>{children}</TemplateCtx.Provider>
  )
}

export default TemplateProvider;
TemplateProvider.propTypes = {
  children: P.node
}
