/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext } from 'react';
import P from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';
import { PageContext } from '../../../config/context/PageContext';

import Loading from '../../../components/Loading';
import { AuthContext } from '../../../config/context/AuthContext';
// import TesteSocket from '../../pages/testes/TesteSocket';

import { pageMap } from '../../../main/pageMap';
import Home from '../../../pages/home/Home';

const PageProvider = ({ children, theme }) => {
  const ctxAuth = useContext(AuthContext);
  const colaborador = ctxAuth.userData?.tipo_usuario === 'Colaborador';
  const params = useParams();

  if (params.slug === 'conexoes') {
    return <Navigate to='/v1/conexoes' />;
  }
  if (params.slug === 'user' || params.slug === 'usuarios') {
    return <Navigate to='/v1/usuarios' />;
  }

  // const [exp, setexpt] = useState(true);
  const PageComponent =
    !colaborador && params?.slug && pageMap[params.slug]
      ? pageMap[params.slug]
      : colaborador && params?.slug === 'profile'
      ? pageMap['profile']
      : colaborador
      ? pageMap['gestao-os']
      : Home;

  return (
    <PageContext.Provider
      value={
        <Suspense fallback={<Loading justifyContent={'center'} alignItems={'center'} />}>
          <PageComponent data={{ ctxAuth }} theme={theme} />
        </Suspense>
      }
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;

PageProvider.propTypes = {
  children: P.node.isRequired,
  theme: P.object,
};
