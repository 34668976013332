import { lazy } from 'react';

export const ApiToken = lazy(() => import('../pages/admin/apitoken/ApiToken'));
export const Bairros = lazy(() => import('../pages/cadastros/Bairros'));
// export const Exemplo = lazy(() => import('../pages/exemplo/Exemplo'));
export const Captacao = lazy(() => import('../pages/cadastros/captacao'));
export const Colaboradores = lazy(() => import('../pages/cadastros/colaboradores/Colaboradores'));
export const Company = lazy(() => import('../pages/cadastros/company/Company'));
export const ContractTelecom = lazy(() => import('../pages/admin/contrato-telecom'));
export const ContratoTelemetria = lazy(() =>
  import('../pages/admin/contrato-telemetria/ContratoTelemetria'),
);
export const ControleQualidade = lazy(() =>
  import('../pages/cadastros/constrole-de-qualidade/ControleQualidade'),
);
export const QualityControlParameter = lazy(() =>
  import('../pages/manutencao/qualityControlParameter/QualityControlParameter'),
);
export const ClientControleQualidade = lazy(() =>
  import('../pages/client-area/gestao-quality-control/QualityControl'),
);
export const Imoveis = lazy(() => import('../pages/gestao-comercial/cadastros/imoveis/Imoveis'));
export const Teste = lazy(() => import('../pages/testes/Teste'));
export const Home = lazy(() => import('../pages/home/Home'));
export const Usuarios = lazy(() => import('../pages/cadastros/usuarios/Usuarios'));
export const Profile = lazy(() => import('../pages/profile/Profile'));
// export const Todo = lazy(() => import('../pages/profile/todo'));
export const Perfil = lazy(() => import('../pages/cadastros/perfil/Perfil'));
export const Grupos = lazy(() => import('../pages/cadastros/grupos/Grupo'));
export const Linhas = lazy(() => import('../pages/cadastros/linhas/Linhas'));
// export const Links = lazy(() => import('../pages/cadastros/links/Links'));
// export const Noticias = lazy(() => import('../pages/cadastros/noticias/Noticias'));
// export const Clientes = lazy(() => import('../pages/cadastros/clients/Clients'));
export const DaeeLogin = lazy(() => import('../pages/cadastros/daee-login/'));
// export const Clientes = lazy(() => import('../../pages/cadastros/clientes/Clientes'));
export const Medidores = lazy(() => import('../pages/cadastros/medidores/Medidores'));
export const MedidorCom = lazy(() => import('../pages/gestao-comercial/cadastros/medidores'));
export const Equipamentos = lazy(() => import('../pages/cadastros/equipamentos'));
export const Operadora = lazy(() => import('../pages/manutencao/operadora/Operadora'));
export const OrdemServicoRC = lazy(() => import('../pages/relatorios/ordem-de-servico-rc'));
export const PlanoLinhas = lazy(() => import('../pages/manutencao/planoLinhas'));
export const PainelVazoes = lazy(() => import('../pages/ferramentas/painelvazao/PainelVazoes'));
// export const PainelSinaisAnalogicos = lazy(() =>
//   import('../pages/ferramentas/painelsinaisanalogico/PainelSinaisAnalogicos'),
// );
export const PainelControle = lazy(() =>
  import('../pages/relatorios/painelControle/ComponentPainelControle'),
);
export const PainelGeral = lazy(() => import('../pages/ferramentas/painelgeral/PainelGeral'));
export const PainelConsumo = lazy(() => import('../pages/relatorios/painel-consumo'));
// export const PainelAcessos = lazy(() => import('../pages/ferramentas/painelacessos/PainelAcessos'));
export const PainelReservacao = lazy(() =>
  import('../pages/relatorios/painel-reservacao/PainelReservacao'),
);

export const Proprietarios = lazy(() =>
  import('../pages/gestao-comercial/cadastros/proprietarios'),
);
export const GestaoVrps = lazy(() => import('../pages/relatorios/gestaoVrps/GestaoVrps'));
export const Setores = lazy(() => import('../pages/cadastros/setores'));
export const Distritos = lazy(() => import('../pages/cadastros/distritos/Distritos'));
export const UnidMedidas = lazy(() => import('../pages/manutencao/unidMedidas/UnidMedidas'));
export const PainelCliente = lazy(() => import('../pages/painelClient/PainelCliente'));
export const GestaoMedidorVazao = lazy(() =>
  import('../pages/v1/relatorios/medidor-vazao/MedidorVazao'),
);
export const GestaoOperacional = lazy(() =>
  import('../pages/relatorios/gestao-operacional/GestaoOperacional'),
);
export const GestaoCalhas = lazy(() => import('../pages/relatorios/getaoCalhas/GestaoCalhas'));
export const Wiki = lazy(() => import('../pages/wiki/Wiki'));
// export const Conexoes = lazy(() => import('../pages/admin/conexoes-discart/Conexoes'));

export const TipoMaterial = lazy(() => import('../pages/manutencao/tipoMaterial/TipoMaterial'));
export const TipoMedidores = lazy(() => import('../pages/manutencao/tipoMedidores/TipoMedidores'));
export const TipoCaptacao = lazy(() => import('../pages/manutencao/tipoCaptacao/TipoCaptacao'));
export const TipoLigacoes = lazy(() =>
  import('../pages/gestao-comercial/manutencao/tipos-de-ligacao/TipoLigacoes'),
);
export const Manancial = lazy(() => import('../pages/manutencao/manancial/Manancial'));
export const GestaoDaee = lazy(() => import('../pages/relatorios/gestao-daee/GestaoDaee'));
export const GestaoPluviometrico = lazy(() =>
  import('../pages/relatorios/gestao-pluviometrico/GestaoPluviometrico'),
);
export const SetoresAbastecimento = lazy(() =>
  import('../pages/cadastros/setores-de-abastecimento/SetoresAbastecimento'),
);
export const GestaoAbastecimento = lazy(() =>
  import('../pages/relatorios/gestao-abastecimento/GestaoAbastecimento'),
);

export const Outorgas = lazy(() => import('../pages/admin/outorgas'));

export const TipoReservatorio = lazy(() =>
  import('../pages/manutencao/tipoReservatorio/TipoReservatorio'),
);
export const OrdemServicos = lazy(() =>
  import('../pages/cadastros/ordem-de-servicos/OrdemServicos'),
);
export const OSTiposServicos = lazy(() =>
  import('../pages/v1/manutencao/os-tipos-de-servicos/OSTiposServicos'),
);
export const GestaoOs = lazy(() => import('../pages/relatorios/gestao-os/GestaoOs'));
export const SetorColaborador = lazy(() =>
  import('../pages/manutencao/setorColaborador/SetorColaborador'),
);
export const AreaAtuacao = lazy(() =>
  import('../pages/manutencao/areaAtuacao/AreaAtuacaoColaborador'),
);
export const TipoManutencao = lazy(() =>
  import('../pages/manutencao/tipoManutencao/TipoManutencao'),
);
export const EquipamentoGroup = lazy(() =>
  import('../pages/admin/grupos/equipamentos/EquipamentoGroup'),
);
export const RegistroFechamento = lazy(() => import('../pages/cadastros/RegistroFechamento'));
export const Sistemas = lazy(() => import('../pages/cadastros/Sistemas'));
export const TiposRemota = lazy(() => import('../pages/manutencao/tiposRemota/TiposRemota'));
export const UnidadesRemotas = lazy(() =>
  import('../pages/cadastros/unidades-remotas/UnidadesRemotas'),
);
export const UserEspecialista = lazy(() =>
  import('../pages/admin/user-especialista/UserEspecialista'),
);

export const V1 = lazy(() => import('../pages/v1'));
